<template>
  <div class="appointmentInformation">
     <div class="left">
       <p>日期：</p>
       <el-date-picker
           v-model="dqtime"
           format="yyyy-MM-dd"
           value-format="yyyy-MM-dd"
           type="date"
           @change="teb"
           placeholder="选择日期">
       </el-date-picker>
<!--       <ul >-->
<!--         <li v-for="itme in timeList" @click="teb(itme)" :class="dqtime==itme?'xz':''">{{itme}}</li>-->
<!--       </ul>-->
     </div>
     <div class="right">
       <div class="ysxx" v-for="item in doctorList" @click="hqyspb(item.ExpertId)">
         <img :src="'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/'+item.ExpertstrImgUrl" alt="">
         <h4>{{item.ExpertName}}</h4>
         <p class="zy">{{item.ExpertWorkTitle}}</p>
         <p class="dw">{{item.ExpertCompany}}</p>
       </div>
       <div v-if="!doctorList.length" style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-size: 20px;">
          暂无排班
       </div>
     </div>

    <el-dialog
        :title="dqtime"
        :visible.sync="dialogVisible"
        width="50%"
    >
      <ul class="phsj">
        <li v-for="item in jzList">
          <p>{{item.ScheduleStartTime}} - {{item.ScheduleEndTime}}</p>
          <p>排号费：{{item.ScheduleCost}}元</p>
          <p>号源数：{{item.ScheduleReservations - item.ScheduleReservationCount}}</p>
          <el-button type="primary" @click="yy(item.ScheduleStartTime, item.ScheduleID)">预约</el-button>
        </li>
      </ul>
    </el-dialog>
  </div>
</template>

<script>
import pf from "@/publicFn/baseFn"
export default {
  name: "appointmentInformation",
  data() {
    return {
      timeList: [],
      doctorList: [],
      dialogVisible: false,
      dqtime: '',
      jzList: [],
      csid: '',
      opid: '',
      exid: ''
    }
  },
  created() {
    if (this.$route.query.csid) {
      this.csid = this.$route.query.csid
      this.opid = this.$route.query.opid
    }
    let arrList = []
    for (let i = 0; i < 15; i++) {
      arrList.push(pf.getTime(i))
    }
    this.timeList = [...arrList]
    this.dqtime = this.timeList[0]
    this._api.publicApi.getScheduleDoctorList(this.timeList[0])
    .then(res => {
      if (res.GetListResult && res.GetListResult.length) {
        this.doctorList = [...res.GetListResult]
      }
    })
  },

  methods: {
    hqyspb(id) {
      this.dialogVisible = true
      this.exid = id
      this._api.publicApi.getDoctorScheduleList(id, this.dqtime)
      .then(res => {
        if (res.GetListResult && res.GetListResult.length > 0) {
          let arrs = res.GetListResult.map(item => {
            let str = item.ScheduleStartTime.toString()
            let js = item.ScheduleEndTime.toString()
            str = str.split('.')
            if (str.length > 1) {
              str = str[0] + ':30'
            }else {
              str = str[0] + ':00'
            }

            js = js.split('.')
            if (js.length > 1) {
              js = js[0] + ':30'
            }else {
              js = js[0] + ':00'
            }
            item.ScheduleStartTime = str
            item.ScheduleEndTime = js
            return item
          })

          this.jzList = [...arrs]
        }
      })
    },

    yy(time, id) {
      this._api.publicApi.insertOppointment(this.exid, this.csid, id, this.opid, this.dqtime+' '+time)
      .then(res => {
        this.$alert(res.msg)
        this.dialogVisible = false
      })
    },

    teb() {
      // console.log(this.dqtime)
      if (!this.dqtime) {
        return
      }

      this._api.publicApi.getScheduleDoctorList(this.dqtime)
          .then(res => {
            if (res.GetListResult && res.GetListResult.length) {
              this.doctorList = [...res.GetListResult]
            }else {
              this.doctorList = []
            }
          })
    }
  }
}
</script>

<style scoped lang="scss">
.appointmentInformation {
  width: 100vw;
  //display: flex;
  height: 76vh;
  overflow-y: auto;
}
.left {
  //height: 76vh;
  //overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ececec;
  font-size: 16px;
  padding: 10px 0;
  //p {
  //  padding: 20px 0;
  //}
  //li {
  //  padding: 10px 0;
  //  cursor: pointer;
  //  border-bottom: 1px solid #c4c4c4;
  //  &:hover {
  //    background: #cecece;
  //  }
  //}
}
.right {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  width: 96vw;
  padding: 0 2vw;
  height: 70vh;
  overflow-y: auto;
  .ysxx {
    width: 130px;
    display: inline-block;
    padding: 15px;
    cursor: pointer;
    border: 1px solid #d5d5d5;
    margin: 10px;
    border-radius: 5px;
    h4 {padding: 10px 0;}
    img  {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    .zy {
      padding-bottom: 10px;
      color: #ff3939;
    }
    .dw {
      font-size: 12px;
      color: #5f5f5f;
    }
  }
}
.phsj {
  li {
    border-bottom: 1px solid #adadad;
    font-size: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    &:hover {background: #eeeeee;}
    p{
      width: 120px;
    }
  }
}
.xz {background: #cecece;}
</style>
